import React, { useState, useEffect, useRef } from 'react';
import './Hero.css';
import VideoSrc from '../../assets/video/Cue Spin_2 (1).mp4';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Hero = () => {
  const [showRandomText, setShowRandomText] = useState(false);
  const [previousRandomAnswer, setPreviousRandomAnswer] = useState('');
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoClick = () => {
    handleVideoPlay();
    clearRandomText();
    setIsVideoPlaying(true);

    // Set a timeout to display random text after 8.5 seconds
    setTimeout(() => {
      const randomAnswers = [
        "Jeg har mine tvivl, men en kaffe med Nick kunne ikke skade",
        "Sandsynligvis, men hvorfor stole på et legetøj",
        "Ja! Men ryst eventuelt igen, hvis du er i tvivl",
        "Hvis du bruger legetøj til at træffe beslutninger, vil Nick formentlig være en forbedring.",
      ];

      let randomAnswer = '';
      do {
        randomAnswer = randomAnswers[Math.floor(Math.random() * randomAnswers.length)];
      } while (randomAnswer === previousRandomAnswer);

      setShowRandomText(randomAnswer);
      setPreviousRandomAnswer(randomAnswer);
      setIsVideoPlaying(false);
    }, 8500);
  };

  const handleVideoPlay = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error('Video play error:', error);
      });
    }
  };

  const clearRandomText = () => {
    setShowRandomText(false);
  };

  return (
    <div className="hero-body">
      {/* Premium background elements */}
      <div className="hero-background-gradient"></div>
      <div className="hero-particles"></div>
      <div className="hero-light-beam"></div>
      
      <div className="hero-content-wrapper">
        <motion.div 
          className="hero-text-section"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.9, ease: "easeOut" }}
        >
          <div className="hero-eyebrow">BESTYRELSESMEDLEM</div>
          <h1 className="hero-title">
            Er Nick Traulsen den rigtige til at sidde i din bestyrelse?
          </h1>
          <p className="hero-subtitle">
            Erfaren iværksætter, investor og bestyrelsesmedlem med fokus på vækst og værdiskabelse
          </p>
          <motion.button 
            className="hero-cta-button"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
            onClick={handleVideoClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleDown} className="button-icon" /> 
            <span>Klik for svar</span>
          </motion.button>
        </motion.div>
        
        <div className="hero-container">
          <motion.div 
            className={`landing--vid ${isVideoPlaying ? 'playing' : ''}`}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
            onClick={handleVideoClick}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >
            <div className="video-glow"></div>
            <div className="video-container">
              <video
                ref={videoRef}
                src={VideoSrc}
                disableRemotePlayback
                disablePictureInPicture
                playsInline
                muted
                onClick={(e) => e.preventDefault()}
              />
              {!isVideoPlaying && !showRandomText && (
                <div className="video-overlay">
                  <div className="play-indicator">
                    <FontAwesomeIcon icon={faArrowAltCircleDown} />
                  </div>
                </div>
              )}
            </div>
            {showRandomText && (
              <motion.div 
                className="random-text"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {showRandomText}
              </motion.div>
            )}
          </motion.div>
        </div>
      </div>

      <motion.div 
        className="custom-hero"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 1.2 }}
      >
        <a href="#experience" className="scroll-link">
          <div className="custom-button">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="custom-dot"
            />
          </div>
          <span className="scroll-text">Scroll ned</span>
        </a>
      </motion.div>
    </div>
  );
};

export default Hero;
