import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import './Experience.css';
import { experienceData } from '../../data/experienceData';
import ExperienceCard from './ExperienceCard';
import { FiFilter, FiCheck } from 'react-icons/fi';
import { motion } from 'framer-motion';

function Experience() {
    const { theme } = useContext(ThemeContext);
    const [selectedCategories, setSelectedCategories] = useState([
        'Medstifter/Founder',
        'Investering',
        'Bestyrelse',
    ]);

    // Function to toggle a category on or off
    const toggleCategory = (category) => {
        if (selectedCategories.includes(category)) {
            if (selectedCategories.length > 1) { // Ensure at least one category is selected
                setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
            }
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    // Filter experiences based on selected categories
    const filteredExperiences = experienceData.filter(exp => 
        selectedCategories.includes(exp.category)
    ).sort((a, b) => a.startYear - b.startYear);

    // Animation variants - FASTER TIMING
    const headerVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: { 
                duration: 0.4, // Faster animation
                ease: "easeOut" 
            }
        }
    };

    const filterContainerVariants = {
        hidden: { opacity: 0, y: 15 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: { 
                duration: 0.3, // Faster animation
                ease: "easeOut",
                delay: 0.1 // Faster delay
            }
        }
    };

    const filterButtonVariants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: custom => ({
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.2, // Faster animation
                delay: 0.1 + (custom * 0.05), // Faster delay
                ease: "easeOut"
            }
        })
    };

    return (
        <div className="timeline-section" id="experience" style={{ backgroundColor: theme.secondary }}>
            <div className="timeline-container">
                <motion.div 
                    className="timeline-header"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.1 }} // Trigger earlier
                    variants={headerVariants}
                >
                    <h1 style={{ color: theme.primary }}>Tidslinje og erfaring</h1>
                    
                    <motion.div 
                        className="filter-container"
                        variants={filterContainerVariants}
                    >
                        <div className="filter-label">
                            <FiFilter className="filter-icon" style={{ color: theme.primary }} />
                            <span style={{ color: theme.tertiary }}>Filter by category:</span>
                        </div>
                        
                        <div className="timeline-filters">
                            <motion.div 
                                custom={0}
                                variants={filterButtonVariants}
                                className={`timeline-filter ${selectedCategories.includes('Medstifter/Founder') ? 'active' : ''}`}
                                onClick={() => toggleCategory('Medstifter/Founder')}
                                style={{ 
                                    borderColor: theme.primary,
                                    backgroundColor: selectedCategories.includes('Medstifter/Founder') ? theme.primary : 'transparent',
                                    color: selectedCategories.includes('Medstifter/Founder') ? '#fff' : theme.tertiary
                                }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {selectedCategories.includes('Medstifter/Founder') && (
                                    <FiCheck className="filter-check-icon" />
                                )}
                                Medstifter/Founder
                            </motion.div>
                            
                            <motion.div 
                                custom={1}
                                variants={filterButtonVariants}
                                className={`timeline-filter ${selectedCategories.includes('Investering') ? 'active' : ''}`}
                                onClick={() => toggleCategory('Investering')}
                                style={{ 
                                    borderColor: theme.primary,
                                    backgroundColor: selectedCategories.includes('Investering') ? theme.primary : 'transparent',
                                    color: selectedCategories.includes('Investering') ? '#fff' : theme.tertiary
                                }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {selectedCategories.includes('Investering') && (
                                    <FiCheck className="filter-check-icon" />
                                )}
                                Investering
                            </motion.div>
                            
                            <motion.div 
                                custom={2}
                                variants={filterButtonVariants}
                                className={`timeline-filter ${selectedCategories.includes('Bestyrelse') ? 'active' : ''}`}
                                onClick={() => toggleCategory('Bestyrelse')}
                                style={{ 
                                    borderColor: theme.primary,
                                    backgroundColor: selectedCategories.includes('Bestyrelse') ? theme.primary : 'transparent',
                                    color: selectedCategories.includes('Bestyrelse') ? '#fff' : theme.tertiary
                                }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {selectedCategories.includes('Bestyrelse') && (
                                    <FiCheck className="filter-check-icon" />
                                )}
                                Bestyrelse
                            </motion.div>
                        </div>
                    </motion.div>
                </motion.div>
                
                <div className="timeline-line" style={{ backgroundColor: theme.primary + '30' }}>
                    {filteredExperiences.map((exp, index) => (
                        <ExperienceCard
                            key={exp.id}
                            id={exp.id}
                            jobtitle={exp.jobtitle}
                            startYear={exp.startYear}
                            image={exp.image}
                            category={exp.category}
                            theme={theme}
                            isEven={index % 2 === 0}
                            index={index}
                            link={exp.link}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Experience;
