import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './Blog.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import Podcasts from '../podcasts/Podcasts';
import Podcast2 from '../podcasts/Podcasts2';
import { motion } from 'framer-motion';

const sampleBlogData = [
    {
        id: 1,
        podcastEmbed: (
            <iframe
                title="Podcast 5"
                src="https://open.spotify.com/embed/episode/5XCiTtVrB3L7uHLL9dbAI8?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px', marginBottom: '20px' }}
            />
        ),
    },
    {
        id: 2,
        podcastEmbed: (
            <iframe
                title="Podcast 6"
                src="https://open.spotify.com/embed/episode/0HYLrxVbeWcf31dh6jQzSF?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px' }}
            />
        ),
    },
    {
        id: 3,
        podcastEmbed: (
            <iframe
                title="Podcast 5"
                src="https://open.spotify.com/embed/episode/6wQ6E0TkoacRVsWAGY1D2G?utm_source=generator&theme=0&t=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px'}}
            />
        ),
    },
    {
        id: 4,
        podcastEmbed: (
            <iframe
                title="Podcast 6"
                src="https://open.spotify.com/embed/episode/7mPq0kt5yLQe7jYvYRkuTW?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px' }}
            />
        ),
    },
];

function Blog() {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        blogTitle: {
            color: theme.primary,
            fontFamily: 'Arial, sans-serif',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        blogDescription: {
            color: '#666',
            fontFamily: 'Verdana, sans-serif',
            fontSize: '14px',
            marginBottom: '20px',
            position: 'relative',
            transition: 'color 0.3s ease',
            '&:hover': {
                color: theme.primary,
            },
        },
        podcast: {
            cursor: 'pointer',
            height:'150px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
            }
          
        },
    }));

    const classes = useStyles();

    return (
        <div className="blog" id="blog" style={{ backgroundColor: theme.secondary }}>
            <div style={{width:'100%'}} >
                <div className="blog--header">
                    <h1 style={{ color: theme.primary }}>PODCASTS</h1>
                    <p style={{ 
                        color: theme.tertiary || 'white', 
                        width: '50%', 
                        marginTop: '-20px', 
                        paddingBottom: '20px', 
                        fontSize: '18px', 
                        textDecoration: 'none', 
                        fontFamily: 'Roboto' 
                    }}>
                        Alle har efterhånden været med i en podcast, men hvis du vil have et indblik i,
                        hvordan jeg tænker, så er disse podcasts nok den bedste måde at lære mig at kende og forstå mine holdninger.
                    </p>
                </div>
                <div className="blog-Heading">
                    <h2 style={{ fontWeight: 'bold', fontFamily: 'Roboto', color: theme.primary }}>Investering og vækst</h2>        
                </div>
            </div>
            <div className="blog--body">
                <div className="blog--bodyContainer">
                    {sampleBlogData.map((blog, index) => (
                        (index % 2 === 0) && (
                            <div className="podcast-container" key={`container-${blog.id}`}>
                                {sampleBlogData.slice(index, index + 2).map((podcast) => (
                                    <motion.div 
                                        key={podcast.id} 
                                        className={`single-blog ${classes.podcast}`} 
                                        style={{ cursor: 'pointer' }}
                                        whileHover={{ 
                                            scale: 1.02,
                                            boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
                                        }}
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ 
                                            opacity: 1, 
                                            y: 0,
                                            transition: {
                                                duration: 0.5,
                                                delay: index * 0.1
                                            }
                                        }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    >
                                        {podcast.podcastEmbed}
                                    </motion.div>
                                ))}
                            </div>
                        )
                    ))}
                </div>
                <Podcasts />
                <Podcast2 />
            </div>
        </div>
    );
}

export default Blog;
