import React from 'react';
import './Experience.css';
import { motion } from 'framer-motion';

function ExperienceCard({ id, jobtitle, startYear, image, category, theme, isEven, index, link }) {
    // Define category colors based on theme
    const categoryColors = {
        'Medstifter/Founder': theme.primary,
        'Investering': theme.primary,
        'Bestyrelse': theme.primary
    };
    
    const categoryColor = categoryColors[category] || theme.primary;
    
    // Animation variants - FASTER TIMING
    const cardVariants = {
        hidden: { 
            opacity: 0, 
            x: isEven ? -50 : 50,
            y: 10
        },
        visible: { 
            opacity: 1, 
            x: 0,
            y: 0,
            transition: { 
                type: "spring", 
                stiffness: 100,
                damping: 15,
                delay: 0.03 * index, // Much faster delay between cards
                duration: 0.4 // Faster animation
            }
        }
    };

    const dotVariants = {
        hidden: { 
            scale: 0,
            opacity: 0
        },
        visible: { 
            scale: 1,
            opacity: 1,
            transition: { 
                delay: 0.03 * index + 0.1, // Faster delay
                duration: 0.3, // Faster animation
                type: "spring",
                stiffness: 300
            }
        }
    };

    const imageVariants = {
        hidden: { 
            opacity: 0,
            scale: 0.9
        },
        visible: { 
            opacity: 1,
            scale: 1,
            transition: { 
                delay: 0.03 * index + 0.1, // Faster delay
                duration: 0.3, // Faster animation
                ease: "easeOut"
            }
        }
    };

    const textVariants = {
        hidden: { 
            opacity: 0,
            y: 10
        },
        visible: { 
            opacity: 1,
            y: 0,
            transition: { 
                delay: 0.03 * index + 0.1, // Faster delay
                duration: 0.3, // Faster animation
                ease: "easeOut"
            }
        }
    };
    
    // Render job title with link if available
    const renderJobTitle = () => {
        if (link) {
            return (
                <a 
                    href={link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="timeline-link"
                    style={{ color: theme.tertiary }}
                >
                    <h3>{jobtitle} <span className="link-icon">↗</span></h3>
                </a>
            );
        }
        return <h3 style={{ color: theme.tertiary }}>{jobtitle}</h3>;
    };
    
    return (
        <motion.div 
            className={`timeline-item ${isEven ? 'left' : 'right'}`}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }} // Trigger earlier
            variants={cardVariants}
        >
            <motion.div 
                className="timeline-dot" 
                style={{ backgroundColor: categoryColor, borderColor: theme.secondary }}
                variants={dotVariants}
                whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
            >
                <span className="timeline-year" style={{ color: '#fff' }}>{startYear}</span>
            </motion.div>
            
            <div className="timeline-content" style={{ 
                borderColor: categoryColor,
                backgroundColor: theme.secondary === '#ffffff' ? '#f8f9fa' : theme.secondary + 'ee',
                boxShadow: `0 10px 30px ${theme.primary}10`
            }}>
                <div className="timeline-content-inner">
                    <motion.div 
                        className="timeline-image-container" 
                        style={{ backgroundColor: '#fff', boxShadow: `0 5px 15px ${theme.primary}20` }}
                        variants={imageVariants}
                        whileHover={{ 
                            scale: 1.1, 
                            transition: { duration: 0.2 } 
                        }}
                    >
                        <img src={image} alt="Company" className="timeline-image" />
                    </motion.div>
                    
                    <motion.div 
                        className="timeline-text"
                        variants={textVariants}
                    >
                        {renderJobTitle()}
                        <motion.span 
                            className="timeline-category" 
                            style={{ backgroundColor: categoryColor }}
                            whileHover={{ 
                                scale: 1.05, 
                                transition: { duration: 0.2 } 
                            }}
                        >
                            {category}
                        </motion.span>
                    </motion.div>
          </div>
        </div>
        </motion.div>
    );
  }
  
  export default ExperienceCard;
  
  
  
  
  
  
  
  
  
  
  
