import React, { useContext, useState, useEffect } from 'react';
import { FiPhone, FiMail, FiCoffee, FiMenu, FiX } from 'react-icons/fi';
import { ThemeContext } from '../../contexts/ThemeContext';
import { contactsData } from '../../data/contactsData';
import emailjs from '@emailjs/browser';
import './Contacts.css';

function Contacts() {
    const { theme } = useContext(ThemeContext);
    const [userEmail, setUserEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [emailError, setEmailError] = useState('');

    // Check if screen is mobile
    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const sendEmail = () => {
        // Validate email
        if (!userEmail) {
            setEmailError('Please enter your email');
            return;
        }
        
        if (!validateEmail(userEmail)) {
            setEmailError('Please enter a valid email');
            return;
        }
        
        setEmailError('');
        setIsSubmitting(true);
        
        emailjs
            .send(
                'service_mhbpttb',
                'template_mvkntsn',
                {
                    to_email: 'nt@themarketingguy.dk',
                    user_email: userEmail,
                    from_name: userEmail,
                    message: 'Your message goes here',
                },
                'iex-ixdKbW2LZUVFs'
            )
            .then(
                (response) => {
                    setSubmitStatus('success');
                    setUserEmail('');
                    setTimeout(() => setSubmitStatus(null), 3000);
                },
                (error) => {
                    setSubmitStatus('error');
                    setTimeout(() => setSubmitStatus(null), 3000);
                }
            )
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
        if (emailError) setEmailError('');
    };

    return (
        <nav className='navbar'>
            <div className='navbar-container'>
                {!isMobile && (
                    <div className='navbar-left'>
                        <div className='coffee-invitation'>
                            <FiCoffee className='coffee-icon' />
                            <p className='coffee-text'>Skal vi drikke en kop kaffe?</p>
                        </div>
                    </div>
                )}
                
                {isMobile && (
                    <div className='mobile-header'>
                        <div className='coffee-invitation'>
                            <FiCoffee className='coffee-icon' />
                            <p className='coffee-text'>Skal vi drikke en kop kaffe?</p>
                        </div>
                        <button className="hamburger-menu" onClick={toggleMobileMenu}>
                            {mobileMenuOpen ? <FiX /> : <FiMenu />}
                        </button>
                    </div>
                )}
                
                <div className={`navbar-content ${isMobile ? 'mobile' : ''} ${mobileMenuOpen || !isMobile ? 'open' : ''}`}>
                    <div className='navbar-center'>
                        <div className='email-form'>
                            <input
                                type='email'
                                placeholder='Enter your email...'
                                className={`contact-input ${submitStatus ? `contact-input-${submitStatus}` : ''} ${emailError ? 'contact-input-error' : ''}`}
                                value={userEmail}
                                onChange={handleEmailChange}
                            />
                            {emailError && <div className="email-error">{emailError}</div>}
                            <button 
                                className={`contact-button ${isSubmitting ? 'submitting' : ''}`}
                                onClick={sendEmail}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Sending...' : 'Ja tak til kaffe'}
                            </button>
                        </div>
                    </div>

                    <div className='navbar-right'>
                        <div className='contact-links'>
                            <a href={`mailto:${contactsData.email}`} className='contact-link'>
                                <FiMail className='contact-icon' />
                                <span className='contact-text'>{contactsData.email}</span>
                            </a>
                            <a href={`tel:${contactsData.phone}`} className='contact-link'>
                                <FiPhone className='contact-icon' />
                                <span className='contact-text'>{contactsData.phone}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Contacts;
