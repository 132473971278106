import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './Podcasts2.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { motion } from 'framer-motion';

const samplePodcastData = [
  {
    id: 1,
    podcastEmbed: (
        <iframe
            title="Podcast 5"
            src="https://open.spotify.com/embed/episode/5e8YYKAUfLyyOoiyNBuwhh?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px' }}
        />
    ),
  },
  {
    id: 2,
    podcastEmbed: (
        <iframe
            title="Podcast 6"
            src="https://open.spotify.com/embed/episode/2BQRODUUF6YCLyyT2EPRnX?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px' }}
        />
    ),
  },
  {
    id: 3,
    podcastEmbed: (
        <iframe
            title="Podcast 7"
            src="https://open.spotify.com/embed/episode/2AeuS2Gysnpiq9EMyqU4Zh?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px' }}
        />
    ),
  },
  {
    id: 4,
    podcastEmbed: (
        <iframe
            title="Podcast 7"
            src=" https://open.spotify.com/embed/episode/2HudLIn0PtgaAeAW4yd5le/video?utm_source=generator&theme=0"
            width="100%"
            height="155"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px' }}
        />
    ),
  }, 
  {
    id: 5,
    podcastEmbed: (
        <iframe
            title="Podcast 7"
            src=" https://open.spotify.com/embed/episode/1mX59EMz5PfoOnXutwVCLu?utm_source=generator&theme=0"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ borderRadius: '12px', }}
        />
    ),
  },
];

function Podcast2() {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        podcast: {
            cursor: 'pointer',
            height:'150px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
            }
        },
    }));

    const classes = useStyles();

    return (
        <div className="podcast2" id="podcast2" style={{ backgroundColor: theme.secondary }}>
            <motion.div 
                className="podcast2-Heading"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ 
                    opacity: 1, 
                    x: 0,
                    transition: { 
                        duration: 0.5, 
                        ease: "easeOut" 
                    }
                }}
                viewport={{ once: true, amount: 0.8 }}
            >
                <h2 style={{ fontWeight: 'bold', fontFamily: 'Roboto', color: theme.primary }}>
                    Tanker om marketing
                </h2>        
            </motion.div>
            
            <div className="podcast2--body">
                <div className="podcast2--bodyContainer">
                    {samplePodcastData.map((podcast, index) => (
                        (index % 3 === 0) && (
                            <div className="podcast2-container" key={`container-${podcast.id}`}>
                                {samplePodcastData.slice(index, index + 3).map((podcast, subIndex) => (
                                    <motion.div 
                                        key={podcast.id} 
                                        className={`single-podcast2 ${classes.podcast}`} 
                                        style={{ cursor: 'pointer' }}
                                        whileHover={{ 
                                            scale: 1.02,
                                            boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
                                        }}
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ 
                                            opacity: 1, 
                                            y: 0,
                                            transition: {
                                                duration: 0.5,
                                                delay: subIndex * 0.1
                                            }
                                        }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    >
                                        <div className="podcast2-wrapper">
                                            {podcast.podcastEmbed}
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Podcast2;
