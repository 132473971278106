import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './Podcasts.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { motion } from 'framer-motion';

const samplePodcastData = [
    {
        id: 1,
        podcastEmbed: (
            <iframe
                title="Podcast 5"
                src="https://open.spotify.com/embed/episode/2jQ4b567Nz8CiEBpv0pg3e?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px',  }}
            />
        ),
    },
    {
        id: 2,
        podcastEmbed: (
            <iframe
                title="Podcast 6"
                src="https://open.spotify.com/embed/episode/2wTA0U6emZkcSZutFXA3aC?utm_source=generator&theme=0"
                width="100%"
                height="155"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px',  }}
            />
        ),
    },
    {
        id: 3,
        podcastEmbed: (
            <iframe
                title="Podcast 7"
                src="https://open.spotify.com/embed/episode/61DeUFwXJWOMcg47Xn5qHM?utm_source=generator&theme=0"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                style={{ borderRadius: '12px', }}
            />
        ),
    },
];

function Podcasts() {
    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        podcast: {
            cursor: 'pointer',
            height:'150px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
            }
        },
    }));

    const classes = useStyles();

    return (
        <div className="podcast" id="podcast" style={{ backgroundColor: theme.secondary }}>
            <motion.div 
                className="podcast-Heading"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ 
                    opacity: 1, 
                    x: 0,
                    transition: { 
                        duration: 0.5, 
                        ease: "easeOut" 
                    }
                }}
                viewport={{ once: true, amount: 0.8 }}
            >
                <h2 style={{ fontWeight: 'bold', fontFamily: 'Roboto', color: theme.primary }}>
                    Tanker om kultur
                </h2>        
            </motion.div>
            
            <div className="podcast--body">
                <div className="podcast--bodyContainer">
                    {samplePodcastData.map((podcast, index) => (
                        (index % 2 === 0) && (
                            <div className="podcast-container" key={`container-${podcast.id}`}>
                                {samplePodcastData.slice(index, index + 2).map((podcast, subIndex) => (
                                    <motion.div 
                                        key={podcast.id} 
                                        className={`single-podcast ${classes.podcast}`} 
                                        style={{ cursor: 'pointer' }}
                                        whileHover={{ 
                                            scale: 1.02,
                                            boxShadow: '0 15px 30px rgba(0,0,0,0.1)'
                                        }}
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ 
                                            opacity: 1, 
                                            y: 0,
                                            transition: {
                                                duration: 0.5,
                                                delay: subIndex * 0.1
                                            }
                                        }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    >
                                        <div className="podcast-wrapper">
                                            {podcast.podcastEmbed}
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Podcasts;
